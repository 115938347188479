.email-photo-section {
    display: flex;
    // height: 300px;
    @media (max-width: 630px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

form {
    display: flex;
    margin: 5px 10px;
    flex-direction: column;
    height: 300px;
    width: 40%;
    @media (max-width: 630px) {
        width: 100%;
        height: 150px;
    }
    input[type="email"], textarea, button {
        background: transparent;
        color: rgba(255, 255, 255, 0.774);
        border-radius: 5px;
        border: 1px solid white;
        padding: 3px 10px;
    }

    input[type="email"] {
        height: 30px;
    }

    button {
        padding: 0px;
        height: 30px;
        background: white;
        color: black;
    }

    textarea {
        margin: 5px 0px;
        height: 90%;
    }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: rgb(1, 1, 1); /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    top: 45px; /* 30px from the bottom */
    border-radius: 5px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.6s 2.5s;
    animation: fadein 0.5s, fadeout 0.6s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 45px; opacity: 1;}
}

@keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 45px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {top: 45px; opacity: 1;}
    to {top: 0; opacity: 0;}
}

@keyframes fadeout {
    from {top: 45px; opacity: 1;}
    to {top: 0; opacity: 0;}
}

.loader {
    width: 18px;
    height: 18px;
    border: 3px solid #464646;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: 5px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.send-button {
    display: flex;
    align-items: center;
    justify-content: center;
}