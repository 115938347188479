.menu {
  position: fixed;
  left: 30px;
  top: 50%;
  color: white;
  transform: translate(0%, -50%);
  padding: 0;
}

.logo {
  position: fixed;
  left: 30px;
  top: 60px;
  color: white;
  transform: translate(0%, -50%);
  height: 150px;
  width: 150px;
}

.cvDownlaod {
  position: fixed;
  right: 60px;
  top: 60px;
  color: white;
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: white;
  
  a {
      color: white;
      text-decoration: none;
  }

  a:hover {
      text-decoration: none;
      color: rgb(201, 201, 201);
  }
}

.fadeIn {
  animation: fadeIn 2s;
  @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
  }
}

.fadeOut {
  animation: fadeOut 2s forwards;
  @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; visibility: hidden;}
  }
}

@media (max-width: 1000px) {
  .menu {
    position: fixed;
    left: 30px;
    top: 90%;
    color: white;
    transform: translate(0%, -50%);
    padding: 0;
    // li {
    //   float: left;
    //   margin: 0 10px;
    // }
  }

  .logo {
    height: 100px;
    width: 100px;
  }

  .cvDownlaod {
    span {
      display: none;
    }
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li span {
    cursor: pointer;
    color: rgba(218, 218, 218, 0.507);
    &:hover {
      color: white;
    }
  }

  li {
    padding-left: 10px;
    border-style: solid;
    border-width: 0 0 0 2px;
    border-color:  rgba(218, 218, 218, 0.507);
  }
}

.activeMenue {
  span {
    color: white;
  }
  border-color: white;
}

img {
  cursor: pointer;
}