// @import './Custom.scss'; // Import custom style

// -----
@import '~bootstrap/scss/bootstrap.scss'; // Import bootstrap styles at the bottom

.App {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-image: 
        linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url("./img/space.jpg");
    background-attachment: fixed;
    background-size: 100% 100%;
    font-family: monospace;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}