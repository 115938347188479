.content-section {
    width: 70%;
    color: white;
  }

h1 {
    width: fit-content;
}

.fade-in { 
    animation: fadeIn 5s;
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
}

.typing {
    animation: typing 3s, blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    @keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%;
    }

}

@keyframes blink {
    50% {
        border-color: transparent
    }
}
}

@media (max-width: 600px) {
    .content-section {
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
        color: white;
    }
}

.my-photo {
    width: auto;
    height: 250px;
    border-radius: 5px;
    margin-bottom: 10px;
}
@media (max-width: 1000px) {
  .my-photo {
    width: auto;
    max-width: 113px;
    height: 130px;
  }
}

/* Define the base style for the button */
.cvButton {
    //Colors
    $color: rgba(255, 255, 255, 0);
    $color-text: white;

    background: none;
    border: 0;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 10px 20px;
    
    // Using inset box-shadow instead of border for sizing simplicity
    box-shadow: inset 0 0 0 2px $color;
    // box-shadow: 10px 5px 5px red;
    color: $color-text;
    font-size: inherit;
    font-weight: 700;
  
    // Required, since we're setting absolute on pseudo-elements
    position: relative;
    vertical-align: middle;
  
    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }
}

.cvButtonClicked {
  box-shadow: inset 0 0 0 2px white;
}

.draw {
    //Colors
    $cyan: white;
    // $hover-color: #c1f9f400;
    
    transition: color 0.25s;
  
    &::before,
    &::after {
      // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }
  
    // This covers the top & right borders (expands right, then down)
    &::before {
      top: 0;
      left: 0;
    }
  
    // And this the bottom & left borders (expands left, then up)
    &::after {
      bottom: 0;
      right: 0;
    }
    
    &:hover, &:focus {
      color: $cyan;
    }
  
    // hover styles
    &:hover::before,
    &:hover::after,
    &:focus::before,
    &:focus::after {
      width: 100%;
      height: 100%;
    }
  
    &:hover::before, &:focus::before {
      border-top-color: $cyan; // Make borders visible
      border-right-color: $cyan;
      transition:
        width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s; // And then height
    }
  
    &:hover::after, &:focus::after {
      border-bottom-color: $cyan; // Make borders visible
      border-left-color: $cyan;
      transition:
        border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
        width 0.25s ease-out 0.5s, // And then exanding width
        height 0.25s ease-out 0.75s; // And finally height
    }

    // @media (min-width: 600px) {
        // &:hover {
        //     box-shadow: inset 0 0 0 2px $hover-color;
        // }
    // }
}

.shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    animation: morph 8s ease-in-out infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 200px;
    transition: all 1s ease-in-out;
    width: auto;
    z-index: 5;

    @keyframes morph {
        0% {
            border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
            background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
          } 
          
        50% {
            border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
            background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
        }
    
        100% {
            border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
            background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
        } 
    }
}

.box {
    --border-size: 3px;
    --border-angle: 0turn;
    // width: auto;
    // height: 200px;
    background-image: conic-gradient(
        from var(--border-angle),
        transparent 0%,
        #213,
        #112 50%,
        #213
      ),
      conic-gradient(from var(--border-angle), 
      transparent 20%, 
      #08f, rgb(255, 254, 254));
    background-size: calc(100% - (var(--border-size) * 2))
        calc(100% - (var(--border-size) * 2)),
      cover;
    background-position: center center;
    background-repeat: no-repeat;
  
    animation: bg-spin 6s linear infinite;

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
}
@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

.skills-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 25px;
}

.skills-content {
  h1 {
    margin-bottom: 30px;
  }
}

h5 {
  display: flex;
  align-items: center;
  width: 130px;
  height: 40px;
}

.skills-icon {
  height: 30px;
  width: auto;
}

@media (min-width: 1000px) {
  h5 {
    float: left;
  }
}

@media (max-width: 1000px) {
  .skills-content {
    h1 {
      margin-bottom: 15px;
    }
  }

  .skills-section {
    height: 20px;
    margin-bottom: 15px;
  }

  .skills-icon {
    height: 20px;
  }
}

.contact-section {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 30px;
  @media (max-width: 630px) {
    width: 100%;
    margin-right: 0px;
  }
}

.contact-icon {
  margin-right: 10px;
  height: 17px;
  width: auto;
}

.contact-text {
  display: inline;
  font-size: 15px;
}